import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import Container from "../components/container";
import Columns from "../components/columns";

export const query = graphql`
  query {
    ofertowanie: file(relativePath: { eq: "ofertowanie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Ofertowanie = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Ofertowanie i zamówienia"
      headline="Szybkie tworzenie dokumentów"
    >
      <SEO
        title="Ofertowanie i zamówienia"
        description="Spójny standard ofertowania oraz szybkie tworzenie zamówień w systemie dla agencji reklamowej i marketingowej• Zapytaj o bezpłatne demo!"
        keywords={["ofertowanie"]}
      />
      <Container className="container container--text">
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <p>
                <strong>Ofertowanie to kluczowy krok</strong> na każdej ścieżce
                sprzedaży. Jak na tym etapie może Cię wesprzeć Empireo? Przede
                wszystkim umożliwiając łatwe zadbanie o{" "}
                <strong>spójny, profesjonalny i atrakcyjny wygląd ofert</strong>
                . Przygotowane w systemie szablony dokumentów zachowują wizualną
                identyfikację Twojej agencji reklamowej i są dostępne dla
                wszystkich handlowców. Zaakceptowaną ofertę w mgnieniu oka{" "}
                <strong>zamienisz na dokument zamówienia</strong>.
              </p>
            </div>
          </div>
        </Columns>
      </Container>
      <Container className="container container--text">
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3>Kluczowe możliwości: </h3>
              <p>
                <ul>
                  <li>
                    <strong>Tworzenie własnych szablonów ofert</strong>, które
                    pozwala Ci jeszcze bardziej spersonalizować ofertę.
                  </li>
                  <li>
                    <strong>
                      Przypomnienia dla zadań powiązanych z ofertą
                    </strong>
                    , dzięki którym zawsze skontaktujesz się na czas z klientem,
                    któremu złożyłeś propozycję.
                  </li>
                  <li>
                    <strong>
                      Powiązanie ofert z szansami sprzedaży i kartą kontrahenta
                    </strong>
                    , co pozwala na łatwe odszukanie informacji na temat
                    przesłanych konkretnemu klientowi propozycji.
                  </li>
                  <li>
                    <strong>Ponawianie zamówień</strong>. Ponieważ w branży
                    reklamowej zdarzają się zamówienia cykliczne lub bardzo do
                    siebie podobne, Empireo oferuje Ci możliwość ich łatwego
                    ponawiania.
                  </li>
                  <li>
                    <strong>Zamówienia wewnętrzne</strong>, dzięki którym Twoi
                    pracownicy mogą składać w systemie zamówienia na przykład na
                    materiały, których będą potrzebowali do realizacji zlecenia.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text-block">
              <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                }}
                images={[data.ofertowanie]}
                alts={[
                  "Spójny standard ofertowania w systemie dla agencji reklamowej i marketingowej",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <p>
          Funkcja ofertowania oraz zarządzania zamówieniami znacznie{" "}
          <strong>ułatwia oraz przyspiesza obsługę klienta</strong>. Wraz ze
          wzrostem Twojej agencji reklamowej lub marketingowej staje się ona
          coraz bardziej przydatna, pomagając zapanować nad ilością różnorodnych
          propozycji przesyłanych licznym kontrahentom.
        </p>
      </Container>
    </FunctionPageLayout>
  );
};

export default Ofertowanie;
